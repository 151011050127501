@import "./vars";

.header_wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 20;

    header {
        padding: 20px 0;

        .flex.row {
            justify-content: flex-end;
        }
    
        .logo_text_wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
    
            .p1 {
                color: $black;
                z-index: 13;
                &.black { color: $black; }
                &.cream { color: $cream; }
                &.bronze { color: $bronze; }
                @media only screen and (max-width: 750px) {
                    font-size: $reg-text * .6;
                }
            }
    
            .logo_wrap {
                z-index: 13;
            }
        }
    
        .logo_wrap {
            z-index: 12;
            margin-top: 6px;
        }
    }
}


#menu_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    -webkit-transition: all .16s ease-in-out;
    -o-transition: all .16s ease-in-out;
    transition: all .16s ease-in-out;
    z-index: 13;

    .text_wrap {
        .menu_text {
            color: $black;
        }

        .menu_text.cream {
            color: $cream !important;
        }

        .menu_text.bronze {
            color: $bronze;
        }

        &.active {
            .menu_text.cream {
                color: $black;
            }

            .menu_text.bronze {
                color: $bronze;
            }
        }
    }

    .wrap {
        display: inline-flex;
        align-items: center;
        position: relative;
        margin-top: 6px;

        .slash {
            height: 12px;
            width: 8px;
            position: relative;
            display: inline-flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
        
            &::before {
                transform: translate3d(-1.7px,0,0) rotate(180deg) skewX(-20deg);
                transition: transform .4s cubic-bezier(.17,.01,.22,1.14);
        
                content: "";
                position: relative;
                display: inline-block;
                top: 0.425px;
                left: 0;
                width: 0.85px;
                height: 100%;
                background-color: $black;
            }

            &.cream {
                &::before {
                    background-color: $cream !important;
                }
            }

            &.bronze {
                &::before {
                    background-color: $bronze !important;
                }
            }
        }
    }

    &:hover {
        letter-spacing: 4px;

        .wrap {
            .slash {
                &::before {
                    transform: translate3d(-1.7px,0,0) rotate(-180deg) skewX(-20deg);
                }
            }
        }
    }

    &.active {
        letter-spacing: 4px;
        .wrap {
            .slash {
                &.cream {
                    &::before {
                        background-color: $black;
                    }
                }

                &::before {
                    transform: translate3d(-1.7px,0,0) rotate(90deg) skewX(0deg);
                    background: $black;
                }
            }
        }
    }
}

// Main Menu
#menu {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 12;

    &.black {
        background: $black;
        box-shadow: 0px 16px 8px rgba(16, 13, 13, 0.8);
    }

    &.white {
        background: $white;
        box-shadow: 0px 16px 8px rgba(255, 255, 255, 0.8);
    }

    &.show {
        height: 100vh;
        padding: 20px;

        .content {
            .link_wrap {
                .link {
                    z-index: 12;
                    opacity: 1;
                }
            }

            @media only screen and (max-width: 750px) {
                margin-top: -120px;
            }
        }
    }

    &.close {
        height: 0;
        top: auto;
        bottom: 0;
    }

    .content {
        .link_wrap {
            text-align: center;
            position: relative;
            margin: 8px 0;

            &.left {
                right: 80px;
            }
            &.right {
                left: 80px;
            }

            @media only screen and (max-width: 750px) {
                 &.left {
                    right: 40px;
                }
                &.right {
                    left: 40px;
                }
            }

            .link {
                -webkit-transition: all .16s ease-in-out;
                    -o-transition: all .16s ease-in-out;
                    transition: all .16s ease-in-out;
                    z-index: -1;
                    position: relative;
                    opacity: 0;

                &:hover {
                    letter-spacing: 4px;
                }

                &.bronze {
                    color: $bronze;
                    &:hover {
                        color: $cream;
                    }
                }

                &.black {
                    color: $black;
                }
            }
        }
    }
}