// $black: #100D0D;
$black: #152238;
$white: #FFF;
$cream: #FAF8EE;
$grey: #E5DFD3;
$bronze: #AEA282;
$blue: #152238;
// $blue: #000080;
$peach: #E7B9B9;
$babyblue: #B8D1CD;

// $font1: 'FoundersGrotesk';
// $font2: 'Montaga';
$font1: 'hoss-round', sans-serif;
$font2: 'Moonwild', serif;

$giant-text: 4rem;
$larger-text: 1.6rem;
$large-text: 1.2rem;
$reg-text: 1rem;
$small-text: .8rem;